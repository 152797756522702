.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.loader-content {
  text-align: center;
  color: #fff;
  position: absolute;
}

.loader-content .funfusion {
  width: 1.2rem;
  height: 1.2rem;
  background: url('/public/images/funfusion.png') center no-repeat;
  background-size: contain;
  border-radius: 0.16rem;
  margin: 0 auto;
  margin-bottom: 0.24rem;
}

.loader-content .loading {
  width: 0.42rem;
  height: 0.42rem;
  border: 0.08rem solid #fafafa;
  border-top: 0.08rem solid #1f1f1f;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}