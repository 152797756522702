.nft-result-bg {
  width: 100%;
  box-shadow: 0 0.02rem 0.2rem 0 #1D1D1D;
  border-radius: 0.2rem;
  filter: blur(0.2rem);
}

.nft-result-package {
  display: block;
  padding: 0.48rem;
  margin-top: -90%;
  position: relative;
}

.nft-result-package .package-poster {
  text-align: center;
}

.nft-result-package .package-poster .img {
  width: 5.2rem;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.nft-result-package .package-remark {
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  margin-top: 0.3rem;
}

.nft-result-package .package-message {
  font-weight: 600;
  font-size: 0.32rem;
  color: #F82041;
  text-align: center;
  margin-top: 0.3rem;
}

.nft-result-package .package-button {
  margin-top: 1.2rem;
}

.nft-result {
  display: block;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
}


.nft-result .vote-info {
  padding: 0.32rem;
  margin-bottom: 0.48rem;
}

.nft-result .vote-info .icon {
  width: 1.6rem;
  height: 1.6rem;
  background: url("/public/images/gift.png") center no-repeat;
  background-size: cover;
  margin: 0 auto;
}

.nft-result .vote-info .box-title {
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  padding: 0.2rem 0;
}

.nft-result .vote-info .box {
  padding: 0;
}

.nft-result .vote-info .box .rows {
  padding: 0.1rem 0;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  font-style: normal;
}

.nft-result .vote-info .box .rows ul {
  padding: 0 0.4rem;
  margin: 0;
}

.nft-result .vote-info .box .opts {
  padding: 0.3rem 0 0.1rem;
  display: flex;
}

.nft-result .title {
  font-weight: 700;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  padding: 0.24rem 0 0;
}

.nft-result .result-info {
  background: #212121;
  border-radius: 0.32rem;
  margin: 0.32rem;
}

.nft-result .result-info .nft-info {
  padding: 0 0 0.32rem 0;
}

.nft-result .result-info .nft-info .nft-pic {
  border-radius: 0.32rem;
  margin: 0.32rem;
}

.nft-result .result-info .nft-info .nft-pic .nft-img {
  width: 5.4rem;
  height: auto;
  margin: 0 auto;
  border-radius: 0.32rem;
}

.nft-result .result-info .nft-info .nft-pic .nft-name {
  width: 5.4rem;
  height: 1.4rem;
  background-image: linear-gradient(0deg, rgba(45, 45, 45, 0.67) 0%, rgba(45, 45, 45, 0) 100%);
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  line-height: 1.4rem;
  text-align: left;
  text-indent: 0.3rem;
  border-radius: 0 0 0.32rem 0.32rem;
  margin: 0 auto;
  position: relative;
  margin-top: -1.4rem;
}

.nft-result .result-info .nft-info .nft-num {
  font-weight: 600;
  font-size: 0.32rem;
  color: #B6B6BE;
  text-align: center;
}

.nft-result-menus {
  list-style: none;
  margin: 0 0.32rem;
  padding: 0;
}

.nft-result-menus li {
  display: flex;
  height: 1rem;
  line-height: 1rem;
  cursor: pointer;
  background: #000;
  border-radius: 0.16rem;
  margin-bottom: 0.2rem;
}

.nft-result-menus li .icon-synthesizer {
  background: url('/public/images/clipboard.png') center no-repeat;
  background-size: 0.48rem;
  width: 1rem;
  height: 1rem;
}

.nft-result-menus li .icon-buy {
  background: url('/public/images/cart.png') center no-repeat;
  background-size: 0.48rem;
  width: 1rem;
  height: 1rem;
}

.nft-result-menus li .icon-withdraw {
  background: url('/public/images/inside-element.png') center no-repeat;
  background-size: 0.48rem;
  width: 1rem;
  height: 1rem;
}

.nft-result-menus li .icon-send {
  background: url('/public/images/shared-user.png') center no-repeat;
  background-size: 0.48rem;
  width: 1rem;
  height: 1rem;
}

.nft-result-menus li .menu-text {
  font-weight: 500;
  font-size: 0.28rem;
  color: #fff;
  text-align: left;
  width: 80%;
}

.nft-result-menus li .icon-arrow-right {
  background: url('/public/images/arrow-right.png') 70% center no-repeat;
  background-size: 0.12rem 0.2rem;
  width: 1rem;
  height: 1rem;
}

.nft-result-menus .bubble {
  position: relative;
  background-color: #212121;
  color: #fff;
  padding: 0.24rem;
  border-radius: 0.16rem;
  margin-bottom: 0.24rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.nft-result-menus .bubble::before {
  content: '';
  position: absolute;
  top: -0.24rem;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0.16rem;
  border-style: solid;
  border-color: transparent transparent #212121 transparent;
}

.nft-result-menus .bubble .bubble-desc {
  font-size: 0.28rem;
  width: 70%;
  color: #B6B6BE;
  font-weight: lighter;
}

.nft-result-menus .bubble .bubble-imgs {
  width: 1.6rem;
  height: 1.6rem;
  overflow: hidden;
  position: relative;
}

.nft-result-menus .bubble .bubble-imgs img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: slide 32s infinite;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(1) {
  animation-delay: 0s;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(2) {
  animation-delay: 4s;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(3) {
  animation-delay: 8s;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(4) {
  animation-delay: 12s;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(5) {
  animation-delay: 16s;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(6) {
  animation-delay: 20s;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(7) {
  animation-delay: 24s;
}

.nft-result-menus .bubble .bubble-imgs img:nth-child(8) {
  animation-delay: 28s;
}

@keyframes slide {
  0% {
    opacity: 0;
  }

  12.5% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  37.5% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.nft-result .info-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.24rem 0.32rem;
}

.nft-result .info-group .x3 {
  width: 30%;
  text-align: center;
}

.nft-result .info-group .x7 {
  width: 70%;
  text-align: center;
}

.nft-result .btn-sm {
  border: 0.03rem solid #F04438;
  background: #fff;
  color: #F04438;
  cursor: pointer;
  border-radius: 0.16rem;
  padding: 0.1rem 0.2rem;
  min-width: 1.6rem;
}

.nft-result .btn-sm:active {
  opacity: 0.7;
  background: #F04438;
  color: #fff;
}

.nft-result .btn-df {
  height: 1rem;
  line-height: 1rem;
  border: none;
  border-radius: 16px;
  border: 0.03rem solid #F04438;
  background: #fff;
  color: #F04438;
  cursor: pointer;
  border-radius: 0.16rem;
  min-width: 1.6rem;
  font-size: 0.32rem;
}

.nft-result .btn-df:active {
  opacity: 0.7;
  background: #F04438;
  color: #fff;
}

.nft-result .facebook-share-button {
  width: 80%;
  height: 0.8rem;
  line-height: 0.7rem;
  background: url('/public/images/facebook.png') 10% center no-repeat;
  background-size: 8%;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 0.28rem;
  border-radius: .16rem;
  margin: 0.24rem auto;
  border: 0.05rem solid #fff;
}

.nft-result .facebook-share-group {
  text-align: center;
}

.nft-result .facebook-share-group a {
  font-size: 0.28rem;
  color: #B6B6BE;
  text-decoration: none;
}

.facebook-share-diglog {
  display: block;
}

.facebook-share-diglog .share-title {
  color: #fff;
  font-size: 0.28rem;
  font-weight: 500;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
  text-align: left;
}

.facebook-share-diglog .share-quote {
  background: #212121;
  border-radius: 0.16rem;
  color: #B6B6BE;
  font-size: 0.28rem;
  font-style: normal;
  padding: 0.32rem;
  text-align: left;
  margin-bottom: 0.24rem;
}

.facebook-share-diglog .share-quote a {
  color: #fff;
}

.facebook-share-diglog .share-button {
  text-align: center;
}

.facebook-share-diglog .flex-button {
  display: flex;
  justify-content: space-between;
}

.facebook-share-diglog .share-button .yes {
  width: 2.45rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  border-radius: 0.16rem;
  color: #fff;
  line-height: 0.8rem;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 0.32rem;
}

.facebook-share-diglog .share-button .no {
  width: 2.45rem;
  height: 0.88rem;
  line-height: 0.8rem;
  background: #3F3F42;
  border-radius: 0.16rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 0.32rem;
}

.contact-form {
  margin-top: 0.24rem;
}

.contact-form .form-txt {
  color: #B6B6BE;
  font-size: 0.28rem;
  font-style: normal;
}

.contact-form .form-row {
  line-height: 0.88rem;
}

.contact-form .form-row label {
  font-weight: 500;
  font-size: 0.28rem;
  color: #fff;
  text-align: left;
}

.contact-form .form-txt .txt-cover {
  text-align: center;
}

.contact-form .form-txt .txt-cover img {
  height: 80px;
}

.contact-form .form-txt .txt-title {
  font-weight: 500;
  font-size: 0.32rem;
  color: #fff;
  text-align: left;
  margin: 0.24rem 0;
}

.contact-form .form-txt .txt-subtitle {
  font-size: 0.28rem;
  color: #fff;
  text-align: left;
  margin: 0.24rem 0;
}

.contact-form .form-txt .txt-smtitle {
  font-size: 0.24rem;
}

.carousel .control-prev.control-arrow::before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow::before {
  border-left: 8px solid #fff;
}

@media only screen and (min-width: 1280px) {
  .nft-result {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
  }

  .nft-result .title {
    font-size: 14px;
    padding: 0px;
    padding-top: 0px;
    padding-top: 20px;
  }

  .nft-result .vote-info {
    padding: 0;
    margin: 0 auto;
    width: 400px;
  }

  .nft-result .vote-info .icon {
    width: 100px;
    height: 100px;
  }

  .nft-result .vote-info .box-title {
    font-size: 16px;
    padding: 10px 0;
  }

  .nft-result .vote-info .box .rows {
    padding: 5px 0;
    font-size: 14px;
  }

  .nft-result .vote-info .box .rows ul {
    padding: 0 20px;
  }

  .nft-result .vote-info .box .opts {
    padding: 10px 0;
    font-size: 14px;
    justify-content: center;
  }

  .nft-result .result-info {
    border-radius: 16px;
    margin: 0;
    margin-top: 10px;
  }

  .nft-result .result-info .nft-info {
    margin: 0;
    margin-top: 10px;
  }

  .nft-result .result-info .nft-info .nft-pic {
    border-radius: 16px;
    width: 500px;
    height: auto;
    margin: 10px auto;
  }

  .nft-result .result-info .nft-info .nft-pic .nft-img {
    width: 500px;
    height: auto;
    border-radius: 16px;
  }

  .nft-result .result-info .nft-info .nft-pic .nft-attr {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    width: 120px;
  }

  .nft-result .result-info .nft-info .nft-pic .nft-name {
    width: 100%;
    height: 70px;
    line-height: 70px;
    font-size: 16px;
    margin: 0 auto;
    margin-top: -70px;
    border-radius: 0 0 16px 16px;
  }

  .nft-result .result-info .nft-info .nft-num {
    font-size: 14px;
    padding: 10px 0 20px 0;
  }

  .nft-result-menus {
    margin: 20px auto;
  }

  .nft-result-menus li {
    height: 50px;
    line-height: 50px;
    margin-bottom: 10px;
    border-radius: 8px;
  }

  .nft-result-menus li .icon-synthesizer {
    background-size: 50%;
    width: 48px;
    height: 48px;
  }

  .nft-result-menus li .icon-buy {
    background-size: 50%;
    width: 48px;
    height: 48px;
  }

  .nft-result-menus li .icon-withdraw {
    background-size: 50%;
    width: 48px;
    height: 48px;
  }

  .nft-result-menus li .icon-send {
    background-size: 50%;
    width: 48px;
    height: 48px;
  }

  .nft-result-menus li .menu-text {
    font-size: 14px;
    width: 90%;
  }

  .nft-result-menus li .icon-arrow-right {
    background-size: 15%;
    width: 48px;
    height: 48px;
  }

  .nft-result-menus .bubble {
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 8px;
  }

  .nft-result-menus .bubble .bubble-desc {
    font-size: 14px;
  }

  .nft-result-menus .bubble .bubble-imgs {
    width: 64px;
    height: 64px;
  }

  .nft-result .info-group {
    padding: 0;
    justify-content: center;
  }

  .nft-result .facebook-share-button {
    width: 280px;
    height: 40px;
    line-height: 40px;
    background: url('/public/images/facebook.png') 10% center no-repeat;
    background-size: 25px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
  }


  .facebook-share-diglog .share-button .yes {
    height: 44px;
    border-radius: 8px;
    line-height: 44px;
    font-size: 14px;
  }

  .facebook-share-diglog .share-button .no {
    height: 44px;
    border-radius: 8px;
    line-height: 44px;
    font-size: 14px;
  }

  .nft-result .facebook-share-group {
    margin-top: 20px;
  }

  .nft-result .facebook-share-group a {
    font-size: 14px;
  }

  .facebook-share-diglog {
    display: block;
  }

  .facebook-share-diglog .share-title {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .facebook-share-diglog .share-quote {
    border-radius: 8px;
    font-size: 14px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .contact-form {
    margin-top: 20px;
  }

  .contact-form .form-txt {
    font-size: 14px;
  }

  .contact-form .form-row {
    line-height: 40px;
  }

  .contact-form .form-row label {
    font-size: 14px;
  }

  .contact-form .input {
    width: 96%;
  }

  .contact-form .textarea {
    width: 96%;
    font-size: 14px;
    min-height: 60px;
    border-radius: 8px;
    padding: 0 10px;
  }

  .contact-form .form-txt .txt-cover img {
    height: auto;
  }

  .contact-form .form-txt .txt-title {
    font-size: 14px;
    margin: 10px 0;
  }

  .contact-form .form-txt .txt-subtitle {
    font-size: 14px;
    margin: 10px 0;
  }

  .contact-form .form-txt .txt-smtitle {
    font-size: 12px;
  }
}