.my-nft-list {
  display: block;
  min-height: 100vh;
  padding: 1.2rem 0.32rem;
}

.my-nft-list .tags-level {
  overflow-x: auto;
  width: 100%;
}

.my-nft-list .tags-level .level {
  font-weight: 600;
  font-size: 0.28rem;
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
}

.my-nft-list .tags-level .level .li {
  color: #B6B6BE;
  text-align: center;
  width: 1.5rem;
  height: 0.68rem;
  background: rgba(153, 153, 153, 0.1);
  border-radius: 0.32rem;
  line-height: 0.68rem;
  margin-right: 0.24rem;
  cursor: pointer;
}

.my-nft-list .tags-level .level .chked {
  cursor: default;
  color: #fff;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
}

.my-nft-list .nfts-list {
  margin-top: 0.24rem;
}

.my-nft-list .nfts-list .li {
  min-height: 2.64rem;
  margin-bottom: 0.24rem;
}

.my-nft-list .nfts-list .li:last-child {
  margin-bottom: 0;
}

.my-nft-list .nfts-list .li .count {
  height: 0.46rem;
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.46rem;
  text-align: left;
  margin-bottom: 0.24rem;
}

.my-nft-list .nfts-list .li .img-list {
  min-height: 1.98rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  text-align: center;
}

.my-nft-list .nfts-list .li .img-list img {
  width: 2.15rem;
  height: auto;
  box-shadow: 0 0.14rem 0.34rem 0.04rem rgba(136, 136, 136, 0.2);
  border-radius: 0.16rem;
  cursor: pointer;
}

@media only screen and (min-width: 1280px) {
  .my-nft-list {
    width: 1200px;
    margin: 0 auto;
    min-height: 50%;
  }

  .my-nft-list .tags-level {
    overflow-x: hidden;
  }

  .my-nft-list .tags-level .level {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-nft-list .tags-level .level .li {
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-right: 20px;
  }

  .my-nft-list .nfts-list {
    margin-top: 20px;
  }

  .my-nft-list .nfts-list .li .count {
    font-size: 16px;
    margin-bottom: 10px;
    height: 40px;
    line-height: 40px;
  }

  .my-nft-list .nfts-list .li .img-list img {
    width: 180px;
    height: auto;
    border-radius: 8px;
  }

}