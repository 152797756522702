.synthesize {
  padding: 1.2rem 0.32rem;
}

.synthesize .nft-title {
  height: 0.3rem;
  font-weight: 500;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.3rem;
  text-align: left;
  text-indent: 0.6rem;
}

.synthesize .nft-card {
  padding: 0.32rem 0;
}

.nft-card .pc-nft-tags {
  display: none;
}

.synthesize .nft-card .package-img {
  display: block;
  height: 5.2rem;
}

.synthesize .nft-card .package-img img {
  width: 5.14rem;
  height: 5.14rem;
}

.synthesize .nft-card .package-img .package-text {
  height: 0.44rem;
  font-weight: 500;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  line-height: 0.44rem;
  position: absolute;
  margin-top: -0.64rem;
  left: 0;
  right: 0;
}

.synthesize .nft-card .package-img .package-help {
  width: 0.44rem;
  height: 0.44rem;
  background: url("/public/images/help.png") center no-repeat;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 1.1rem;
  margin-top: -0.7rem;
}

.synthesize .nft-card .package-chked img {
  border: 0.06rem solid #fff;
  box-shadow: 0 0 0.34rem 0.04rem #f8204138;
  border-radius: 0.32rem;
}

.synthesize .nft-card .result-message {
  background: #22201E;
  box-shadow: 0 0.14rem 0.34rem 0 #4b34de1a;
  border-radius: 0.16rem;
  margin: 0.24rem auto;
  text-align: left;
  padding: 0.24rem;
  cursor: pointer;
}

.synthesize .nft-card .result-message .message-msg {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
}

.synthesize .nft-card .result-message .message-go {
  height: 0.44rem;
  font-size: 0.32rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.01rem;
  text-align: left;
  line-height: 0.44rem;
  margin-top: 0.1rem;
}

.synthesize .nft-card .icon-up-arrow {
  width: 0.48rem;
  height: 0.48rem;
  background: url("/public/images/up-arrow.png") center no-repeat;
  background-size: contain;
  margin: 0.24rem auto;
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.synthesize .nft-card .nft-select {
  list-style: none;
  padding: 0;
  margin: 0.24rem auto;
  display: flex;
  flex-wrap: wrap;
  gap: 0.28rem;
  text-align: center;
}

.synthesize .nft-card .nft-select li {
  width: 2.1rem;
  height: 2.1rem;
  float: left;
}

.synthesize .nft-card .nft-select li img {
  width: 1.98rem;
  height: 1.98rem;
  border-radius: 0.16rem;
}

.synthesize .nft-card .nft-select li .lev-text {
  width: 1.98rem;
  font-weight: 500;
  font-size: 0.24rem;
  color: #fff;
  position: relative;
  margin-top: -0.8rem;
  text-align: center;
}

.synthesize .nft-card .selection {
  height: 1.2rem;
  line-height: 1.2rem;
  background: #212121 url("/public/images/ai.png") 20% center no-repeat;
  background-size: 0.8rem;
  box-shadow: 0 0.14rem 0.34rem 0 #4b34de1a;
  border-radius: 0.16rem;
  font-weight: 500;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.24rem;
}

.synthesize .nft-card .selection:active {
  background: #99999f url("/public/images/ai.png") 20% center no-repeat;
}

.synthesize .nft-card .operation {
  text-align: center;
  margin-top: 0.48rem;
}

.select-synthesis {
  overflow: auto;
}

.select-synthesis .select-synthesis-toobar {
  padding-top: 0.48rem;
  padding-left: 0.48rem;
  padding-right: 0.48rem;
}

.select-synthesis .select-synthesis-toobar .tags-bar {
  height: 0.68rem;
  line-height: 0.68rem;
  margin-bottom: 0.48rem;
}

.select-synthesis .select-synthesis-toobar .tags-bar .tags-list {
  display: flex;
}

.select-synthesis .select-synthesis-toobar .tags-bar .tags-list .tag {
  width: 1.5rem;
  height: 0.68rem;
  background: rgba(153, 153, 153, 0.1);
  border-radius: 0.32rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  line-height: 0.68rem;
  margin-right: 0.2rem;
  text-align: center;
  cursor: pointer;
}

.select-synthesis .select-synthesis-toobar .tags-bar .tags-list .chk {
  width: 1.5rem;
  height: 0.68rem;
  border-radius: 0.32rem;
  line-height: 0.68rem;
  text-align: center;
  margin-right: 0.2rem;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
  font-weight: 600;
  font-size: 0.28rem;
  color: #3D230D;
  cursor: default;
}

.select-synthesis .select-synthesis-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  overflow: auto;
  height: 50vh;
  margin-bottom: 0.3rem;
}

.select-synthesis .select-synthesis-list li {
  width: 2.12rem;
  height: 2.12rem;
  float: left;
}

.select-synthesis .select-synthesis-list li img {
  width: 1.98rem;
  height: 1.98rem;
  border-radius: 0.16rem;
}

.select-synthesis .select-synthesis-list li .img-check {
  border: 0.03rem solid #B81902;
}

.select-synthesis .select-synthesis-list li .nft-check {
  background: #000 url("/public/images/nft-check.png") center no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  background-size: cover;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  margin-top: -0.68rem;
  margin-left: -1.38rem;
}

.select-synthesis .center {
  padding: 0 0.48rem 1rem 0.48rem;
}

.select-synthesis .select-synthesis-num {
  height: 0.4rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
  line-height: 0.4rem;
  margin-bottom: 0.3rem;
}

.synthesis-help {
  display: block;
}

.synthesis-help .lix {
  min-height: 1rem;
  background: #ffffff1a;
  border: 0.01rem solid #ffffff99;
  border-radius: 0.16rem;
  margin-bottom: 0.24rem;
  display: flex;
  padding: 0.2rem;
}

.synthesis-help .lix .icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.1rem
}

.synthesis-help .lix .vote-icon {
  background: url('/public/images/help-vote.png') center no-repeat;
  background-size: contain;
}

.synthesis-help .lix .tag-icon {
  background: url('/public/images/help-tag.png') center no-repeat;
  background-size: contain;
}

.synthesis-help .lix .package-icon {
  background: url('/public/images/help-package.png') center no-repeat;
  background-size: contain;
}

.synthesis-help .lix .content {
  justify-content: left;
  display: flex;
  align-items: center;
  width: 80%;
}

.synthesis-help .lix .content .title {
  font-weight: 600;
  font-size: 0.22rem;
  color: #FFFFFF;
}

.synthesis-help .lix .content .desc {
  font-weight: 600;
  font-size: 0.22rem;
  text-align: left;
  color: #B81902;
}

.synthesis-help .lix .content1 {
  width: 80%;
}

.synthesis-help .lix .content1 .subtitle {
  min-height: 0.3rem;
  font-weight: 600;
  font-size: 0.22rem;
  color: #FFFFFF;
  line-height: 0.3rem;
  text-align: left;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.synthesis-help .lix .content1 .subcontent {
  height: 0.6rem;
  font-weight: 600;
  font-size: 0.22rem;
  color: #B81902;
}

@media only screen and (min-width: 1280px) {
  .synthesize {
    width: 1200px;
    margin: 0 auto;
    min-height: 50%;
    text-align: center;
  }

  .synthesize .nft-title {
    font-size: 16px;
    text-indent: 0;
    text-align: center;
    height: 40px;
    line-height: 0;
  }

  .synthesize .nft-card {
    padding: 0px;
    text-align: center;
  }

  .synthesize .nft-card .package-img {
    display: none;
  }

  .synthesize .nft-card .package-chked img {
    border: 3px solid #fff;
  }

  .synthesize .nft-card .pc-nft-tags {
    display: flex;
    margin: 0 auto;
    width: 800px;
    height: 200px;
  }

  .synthesize .nft-card .pc-nft-tags .package-img1 {
    height: 200px;
    width: 200px;
    position: relative;
  }

  .synthesize .nft-card .pc-nft-tags .package-img1 img {
    width: 180px;
    height: 180px;
    border-radius: 16px;
  }

  .synthesize .nft-card .pc-nft-tags .package-img1 .package-help1 {
    width: 20px;
    height: 20px;
    background: url("/public/images/help.png") center no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: 160px;
    margin-top: 10px;
    position: absolute;
  }

  .synthesize .nft-card .pc-nft-tags .package-img1 .package-text1 {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    margin-top: -40px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    width: 100%;
  }

  .synthesize .nft-card .result-message {
    padding: 0 20px;
    margin: 20px auto;
    width: 640px;
  }

  .synthesize .nft-card .result-message .message-msg {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .synthesize .nft-card .result-message .message-go {
    font-size: 16px;
    margin-top: 0;
    height: 32px;
    line-height: 20px;
  }

  .synthesize .nft-card .icon-up-arrow {
    height: 24px;
    width: 24px;
    margin: 20px auto;
  }

  .synthesize .nft-card .nft-select {
    width: 690px;
    margin: 0px auto;
    gap: 10px;
  }

  .synthesize .nft-card .nft-select li {
    width: 130px;
    height: 130px;
  }

  .synthesize .nft-card .nft-select li img {
    width: 120px;
    height: 120px;
    border-radius: 8px;
  }

  .synthesize .nft-card .nft-select li .lev-text {
    width: 100%;
    font-size: 10px;
    margin-top: -40px;
  }

  .synthesize .nft-card .selection {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    background-size: 24px;
    width: 260px;
    margin: 0 auto;
    border-radius: 8px;
  }

  .synthesize .nft-card .operation {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .select-synthesis {
    width: 1200px;
    margin: 0 auto;
    overflow: auto;
  }

  .synthesis-help {
    display: block;
  }

  .synthesis-help .lix {
    min-height: 60px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
  }

  .synthesis-help .lix .icon {
    width: 60px;
    height: 60px;
  }

  .synthesis-help .lix .content .title {
    font-size: 14px;
  }

  .synthesis-help .lix .content .desc {
    font-size: 14px;
    height: auto;
  }

  .synthesis-help .lix .content1 .subtitle {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .synthesis-help .lix .content1 .subcontent {
    font-size: 14px;
    height: auto;
  }

  .select-synthesis .select-synthesis-toobar {
    margin-top: 20px;
    padding: 0 5px;
  }

  .select-synthesis .select-synthesis-toobar .tags-bar {
    height: 40px;
    line-height: 40px;
    margin-bottom: 0px;
  }

  .select-synthesis .select-synthesis-toobar .tags-bar .tags-list .tag {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
  }

  .select-synthesis .select-synthesis-toobar .tags-bar .tags-list .chk {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
  }

  .select-synthesis .select-synthesis-list {
    margin-bottom: 10px;
  }

  .select-synthesis .select-synthesis-list li {
    width: 146px;
    height: 146px;
  }

  .select-synthesis .select-synthesis-list li img {
    width: 130px;
    height: 130px;
    border-radius: 16px;
  }

  .select-synthesis .select-synthesis-list li .nft-check {
    width: 30px;
    height: 30px;
    margin-top: -30px;
    margin-left: -110px;
  }

  .select-synthesis .center {
    padding: 20px 0;
  }

  .select-synthesis .select-synthesis-num {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .select-synthesis .btn-synthesize {
    width: 400px;
  }

}