.buy-nft {
  display: block;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
}

.buy-nft .toolbar {
  line-height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.32rem;
  text-align: center;
}

.buy-nft .toolbar .tags {
  width: 100%;
  overflow: auto;
  height: 1.5rem;
}

.buy-nft .toolbar .tags ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
}

.buy-nft .toolbar .tags ul li {
  width: 1.06rem;
  height: 1.06rem;
  border-radius: 0.16rem;
  margin-right: 0.16rem;
}

.buy-nft .toolbar .tags ul li .pic {
  width: 1.06rem;
  height: 1.06rem;
  border-radius: 0.16rem;
}

.buy-nft .toolbar .tags ul li .tags-text {
  height: 0.3rem;
  line-height: 0.3rem;
  font-weight: 500;
  font-size: 0.22rem;
  color: #fff;
  text-align: center;
  margin-top: -0.68rem;
  background: rgba(25, 19, 17, 0.6);
  border-radius: 0 0 0.08rem 0.08rem;
  position: relative;
}

.buy-nft .toolbar .tags .chked {
  border: 0.03rem solid #fff;
  cursor: default;
  position: relative;
}

.buy-nft .toolbar .tags .chked::after {
  content: "";
  position: absolute;
  bottom: -0.17rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 0.15rem solid transparent;
  border-right: 0.15rem solid transparent;
  border-top: 0.15rem solid #fff;
}

.buy-nft .popo-box {
  background: #212121;
  box-shadow: 0 -0.1rem 0.28rem 0 rgba(136, 136, 136, 0.08);
  border-radius: 0.32rem;
  padding: 0.32rem;
  margin-top: -1rem;
}

.buy-nft .popo-box .poster {
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
}

.buy-nft .popo-box .poster img {
  width: 100%;
  border-radius: 0.32rem;
}

.buy-nft .popo-box .poster .level {
  width: 1.58rem;
  height: 0.78rem;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
  border-radius: 0.39rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.78rem;
  text-align: center;
  position: relative;
  margin-left: 0.2rem;
  margin-bottom: -1rem;
}

.buy-nft .popo-box .poster .name {
  font-weight: 600;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 1.4rem;
  text-align: left;
  padding-left: 0.4rem;
  margin-top: -1.38rem;
  height: 1.4rem;
  background-image: linear-gradient(to top,
      rgba(45, 45, 45, 0.67) 0%,
      rgba(45, 45, 45, 0) 100%);
  opacity: 0.71;
  border-radius: 0 0 0.48rem 0.48rem;
}

.buy-nft .popo-box .instructions {
  font-size: 0.32rem;
  font-style: normal;
  font-weight: 500;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.3rem;
}

.buy-nft .popo-box .instructions .rows {
  display: block;
}

.buy-nft .popo-box .instructions .rows .title {
  color: #fff;
}

.buy-nft .popo-box .instructions .rows .desc {
  padding: 0.2rem 0.3rem;
  list-style: initial;
  margin: 0;
}

.buy-nft .popo-box .vnd {
  height: 0.66rem;
  line-height: 0.66rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}

.buy-nft .popo-box .vnd .number {
  width: 60%;
  font-size: 0.4rem;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  justify-content: left;
  display: flex;
}

.buy-nft .popo-box .vnd .number .original-price {
  color: #B6B6BE;
  font-size: 0.32rem;
  padding-right: 0.24rem;
  text-decoration: line-through;
}

.buy-nft .popo-box .vnd .number .buy-price {
  color: #fff;
}

.buy-nft .popo-box .vnd .quantity {
  height: 0.56rem;
  line-height: 0.56rem;
  display: flex;
}

.buy-nft .popo-box .vnd .quantity .decrease {
  width: 0.56rem;
  height: 0.56rem;
  background: url("/public/images/decrease.png") center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.buy-nft .popo-box .vnd .quantity .number {
  width: 1.2rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #B81902;
  letter-spacing: 0.02rem;
  text-align: center;
  background: #212121;
  border: none;
  outline: navajowhite;
}

.buy-nft .popo-box .vnd .quantity .increase {
  width: 0.56rem;
  height: 0.56rem;
  background: url("/public/images/increase.png") center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.buy-nft .popo-box .tip-message {
  height: 0.76rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #F04438;
  line-height: 0.38rem;
  text-align: center;
  margin: 0 0.48rem;
}

.buy-nft .button-box {
  margin: 0 0.48rem 0.6rem;
}

.buy-nft .select-box {
  margin: 0 0.48rem;
}

.buy-nft .tip-box {
  color: #CBCBCB;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
}

.buy-nft .carousel .slide {
  transform: scale(0.8);
}

.buy-nft .carousel .selected {
  transform: scale(0.84);
}

.buy-nft-help {
  overflow: auto;
  height: 70vh;
  padding: 0.48rem;
  z-index: 3;
}

.buy-nft-help .instructions {
  font-weight: 500;
  font-size: 0.28rem;
  color: #FFFFFF;
  text-align: left;
  margin: 0.48rem 0;
}

.buy-nft-help .instructions .link {
  color: #F04438;
  text-decoration-line: underline;
  cursor: pointer;
}

.buy-nft-help .package-box {
  text-align: center;
  margin-bottom: 0.3rem;
}

.buy-nft-help .package-box .img {
  width: 5.2rem;
  height: auto;
}

.buy-nft-help .package-box .package-type {
  color: #fff;
  font-size: 0.28rem;
  font-weight: 400;
  padding: 0.1rem 0;
}

.buy-nft-help .package-box .package-type .package-desc {
  color: #888;
  font-size: 0.24rem;
  font-weight: 400;
  padding: 0.1rem 0;
}

@media only screen and (min-width: 1280px) {
  .buy-nft {
    display: block;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
  }

  .buy-nft .toolbar {
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  .buy-nft .toolbar .tags {
    width: 640px;
    overflow: hidden;
    height: auto;
    margin: 0 auto;
  }

  .buy-nft .toolbar .tags ul li {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .buy-nft .toolbar .tags ul li .pic {
    width: 80px;
    height: 80px;
  }

  .buy-nft .toolbar .tags ul li .tags-text {
    height: 30px;
    line-height: 30px;
    font-weight: 500;
    font-size: 16px;
    margin-top: -50px;
  }

  .buy-nft .popo-box {
    border-radius: 16px;
    width: 800px;
    margin: 0 auto;
    margin-top: -30px;
  }

  .buy-nft .popo-box .poster {
    margin: 0px;
    margin-bottom: 20px;
  }

  .buy-nft .popo-box .poster img {
    max-width: 500px;
    border-radius: 16px;
    height: auto;
  }

  .buy-nft .popo-box .instructions {
    margin-bottom: 0;
    font-size: 14px;
  }

  .buy-nft .popo-box .instructions .rows .title {
    font-size: 16px;
    height: 40px;
    margin-bottom: 0;
    line-height: 40px;
  }

  .buy-nft .popo-box .instructions .rows .desc {
    font-size: 14px;
    margin: 0px;
    padding: 0 20px;
  }

  .buy-nft .popo-box .instructions .rows .desc li {
    padding: 10px 0;
  }

  .buy-nft .popo-box .vnd {
    margin-bottom: 0;
    height: 40px;
    line-height: 40px;
    margin-top: 20px;
  }

  .buy-nft .popo-box .vnd .number {
    font-size: 30px;
  }

  .buy-nft .popo-box .vnd .quantity {
    height: 30px;
    line-height: 30px;
  }

  .buy-nft .popo-box .vnd .quantity .decrease {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .buy-nft .popo-box .vnd .quantity .number {
    height: 30px;
    line-height: 30px;
    font-size: 24px;
  }

  .buy-nft .popo-box .vnd .number .original-price {
    font-size: 18px;
    padding-right: 20px;
  }

  .buy-nft .popo-box .vnd .quantity .increase {
    width: 30px;
    height: 30px;
  }

  .buy-nft .button-box {
    margin: 0;
    text-align: center;
  }

  .buy-nft .select-box {
    width: 680px;
    margin: 0 auto;
    text-align: center;
  }

}