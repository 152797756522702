.faq-container {
  display: block;
  padding: 1.2rem 0.32rem;
}

.faq-container h1 {
  color: #fff;
  font-size: 0.32rem;
  padding-top: 0.24rem;
}

.faq-container h2 {
  font-size: 0.32rem;
  color: #fff;
}

.faq-container h2 svg {
  font-size: 0.28rem;
  color: #B6B6BE;
  padding: 0 0.24rem;
  cursor: pointer;
}

.faq-container h3 {
  font-size: 0.32rem;
  color: #B6B6BE;
}

.faq-container .faq-section {
  background: #212121;
  border-radius: 0.16rem;
  padding: 0.24rem;
  margin-bottom: 0.24rem;
  color: #B6B6BE;
}

.faq-container .faq-section h2,
.faq-container .faq-section h3 {
  margin-top: 0;
}

.faq-container .faq-section p,
.faq-container .faq-section ul {
  font-size: 0.26rem;
  line-height: 1.6;
  font-weight: 100;
}

.faq-container .faq-section a {
  color: #fff;
}

.faq-container .faq-section ul {
  padding: 0.1rem 0.24rem;
}

.faq-container .faq-section .pic {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 0.16rem;
  margin: 0.24rem 0;
}

@media only screen and (min-width: 1280px) {
  .faq-container {
    width: 1200px;
    margin: 0 auto;
  }

  .faq-container h1,
  .faq-container h2,
  .faq-container h3 {
    font-size: 16px;
  }

  .faq-container h2 svg {
    font-size: 14px;
    padding: 0 10px;
  }

  .faq-container .faq-section p,
  .faq-container .faq-section ul {
    font-size: 14px;
  }

  .faq-container .faq-section .pic {
    max-width: 375px;
    margin: 10px auto;
  }
}