.my-rights {
  display: block;
  padding: 1.2rem 0.32rem;
}

.my-rights .lev-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.my-rights .lev-list li {
  padding: 0.24rem;
  background: rgba(136, 136, 136, 0.2);
  border-radius: 0.32rem;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.24rem;
  word-break: break-all;
  word-spacing: normal;
}

.my-rights .lev-list li:first-child {
  margin-top: 0.24rem;
  font-size: 500;
}

.my-rights .lev-list li span {
  text-decoration: underline;
}

.my-rights .lev-list .chked {
  color: #fff;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  box-shadow: 0 0.14rem 0.34rem 0 rgb(75 52 222 / 10%);
}


@media only screen and (min-width: 1280px) {
  .my-rights {
    width: 1200px;
    margin: 0 auto;
  }

  .my-rights .lev-list li {
    font-size: 14px;
    height: 40px;
    border-radius: 16px;
    margin-bottom: 20px;
    line-height: 40px;
  }

  .my-rights .lev-list .chked {
    padding-bottom: 20px;
  }

}