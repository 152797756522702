.nft-send {
  display: block;
  padding: 1.2rem 0.32rem;
}

.nft-send .gift {
  margin: 0.24rem 0;
  justify-content: center;
  display: flex;
  position: relative;
  z-index: 1;
}

.nft-send .gift .sm-gift {
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  margin-top: -0.24rem;
  margin-left: 2.4rem;
  z-index: 1;
}

.nft-send .gift .sm-gift img {
  width: 0.6rem;
  height: 0.6rem;
}

.nft-send .gift img {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.16rem;
}

.nft-send .prompt {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.24rem;
}

.nft-send .prompt .span {
  font-size: 0.26rem;
  color: #B6B6BE;
  margin-top: 0.12rem;
}

.nft-send .prompt .span span {
  color: #fff;
  cursor: pointer;
}

.nft-send .value {
  margin-bottom: 0.24rem;
}

.nft-send .error {
  font-weight: 400;
  font-size: 0.28rem;
  color: #f82041;
  text-align: left;
  margin-bottom: 0.24rem;
}

@media only screen and (min-width: 1280px) {
  .nft-send {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
    text-align: center;
  }

  .nft-send .gift {
    margin: 0 0 10px 0;
  }

  .nft-send .gift .sm-gift {
    width: 44px;
    height: 44px;
    margin-top: -20px;
    margin-left: 500px;
  }

  .nft-send .gift .sm-gift img {
    width: 44px;
    height: 44px;
  }

  .nft-send .gift img {
    width: 500px;
    height: 500px;
    border-radius: 16px;
  }

  .nft-send .prompt {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    height: 40px;
    line-height: 40px;
  }

  .nft-send .prompt .span {
    font-size: 14px;
    margin-top: 0;
  }

  .nft-send .value {
    margin-bottom: 10px;
  }

  .nft-send .error {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .nft-send .input {
    width: 97%;
  }
}