.nft-withdraw-result {
  display: block;
  padding: 1.2rem 0.32rem;
}

.nft-withdraw-result .pic {
  width: auto;
  height: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 0.24rem;
}

.nft-withdraw-result .pic img {
  width: 100%;
  height: auto;
  box-shadow: 0 0.02rem 0.2rem 0 #1d1d1d;
  border-radius: 0.32rem;
}

.nft-withdraw-result .li {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.2rem;
}

.nft-withdraw-result .li span {
  color: #B6B6BE;
  padding: 0 0.1rem;
  word-wrap: break-word;
}

.nft-withdraw-result .li .chain,
.nft-withdraw-result .li svg {
  color: #fff;
  vertical-align: middle;
  padding: 0 0.05rem;
}

.nft-withdraw-result .li .chain{
  text-decoration-line: underline;
  cursor: pointer;
}

.nft-withdraw-result .line {
  height: 0.02rem;
  background: #ffffff;
  opacity: 0.1;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
}


.nft-withdraw-result .paystate {
  width: auto;
  margin-top: 0.32rem;
}

.nft-withdraw-result .paystate .icon {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 auto;
  margin-bottom: 0.3rem;
}

.nft-withdraw-result .paystate .msg {
  height: 0.46rem;
  font-weight: 500;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.46rem;
  text-align: center;
}

.nft-withdraw-result .paystate .msg .success {
  color: #fff;
}

.nft-withdraw-result .paystate .msg .failed {
  color: #fff;
}

.nft-withdraw-result .success .icon {
  background: url("/public/images/pay-success.png") center no-repeat;
  background-size: cover;
}

.nft-withdraw-result .failed .icon {
  background: url("/public/images/pay-failed.png") center no-repeat;
  background-size: cover;
}

.nft-withdraw-result .cmd {
  margin-top: 0.6rem;
  display: flex;
}

.nft-withdraw-result .cmd .withdraw-btn {
  width: 2.7rem;
  height: 0.98rem;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
  border-radius: 0.49rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.98rem;
  letter-spacing: 0.02rem;
  text-align: center;
  cursor: pointer;
}

.nft-withdraw-result .cmd .send-btn {
  width: 2.7rem;
  height: 0.98rem;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
  border-radius: 0.49rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.98rem;
  letter-spacing: 0.02rem;
  text-align: center;
  margin-left: 0.3rem;
  cursor: pointer;
}

.nft-withdraw-result .withdraw-result-box {
  background: #212121;
  padding: 0.32rem;
  border-radius: 0.32rem;
  margin: 0.32rem 0;
}


@media only screen and (min-width: 1280px) {
  .nft-withdraw-result {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
    text-align: center;
  }

  .nft-withdraw-result .pic {
    margin-bottom: 10px;
  }

  .nft-withdraw-result .pic img {
    width: 500px;
    height: auto;
    border-radius: 16px;
  }

  .nft-withdraw-result .li {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .nft-withdraw-result .li .chain,
  .nft-withdraw-result .li svg {
    padding: 0 5px;
  }

  .nft-withdraw-result .li span {
    padding: 0;
  }

  .nft-withdraw-result .line {
    height: 1px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .nft-withdraw-result .paystate {
    margin-top: 0;
  }

  .nft-withdraw-result .paystate .icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .nft-withdraw-result .paystate .msg {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }

  .nft-withdraw-result .cmd {
    margin-top: 10px;
    text-align: center;
    display: block;
  }

  .nft-withdraw-result .withdraw-result-box {
    border-radius: 16px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}