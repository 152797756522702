.my-nft-details {
  display: block;
  padding: 1.2rem 0.32rem;
}

.my-nft-details .pic {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
}

.my-nft-details .pic img {
  width: 100%;
  height: auto;
  box-shadow: 0 0.02rem 0.2rem 0 #1d1d1d;
  border-radius: 0.32rem;
}

.my-nft-details .li {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.24rem;
}

.my-nft-details .li span {
  color: #FF4405;
  padding: 0 0.1rem;
}

.my-nft-details .cmd {
  margin-top: 0.24rem;
  display: block;
}

.my-nft-details .cmd .withdraw-btn {
  width: 50%;
  height: 0.88rem;
  border-radius: 0.16rem;
  font-weight: 500;
  font-size: 0.32rem;
  line-height: 0.88rem;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  color: #FFFFFF;
  border: none;
}

.my-nft-details .cmd .send-btn {
  width: 50%;
  height: 0.88rem;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
  border-radius: 0.16rem;
  font-weight: 500;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  margin-left: 0.24rem;
  cursor: pointer;
  border: none;
}

.my-nft-details .facebook-share-button {
  height: 0.8rem;
  line-height: 0.8rem;
  background: url('/public/images/facebook.png') 20% center no-repeat;
  background-size: 8%;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 0.28rem;
  border-radius: .16rem;
  margin: 0.24rem auto;
  border: 0.05rem solid #fff;
}

@media only screen and (min-width: 1280px) {
  .my-nft-details {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
    text-align: center;
  }

  .my-nft-details .pic {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .my-nft-details .pic img {
    width: 500px;
    height: auto;
    border-radius: 16px;
  }

  .my-nft-details .li {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .my-nft-details .cmd {
    margin-top: 20px;
    display: block;
  }

  .my-nft-details .cmd .withdraw-btn {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    border-radius: 8px;
  }

  .my-nft-details .cmd .send-btn {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    border-radius: 8px;
  }

  .my-nft-details .facebook-share-button {
    width: 400px;
    height: 44px;
    line-height: 44px;
    background: url('/public/images/facebook.png') 25% center no-repeat;
    background-size: 25px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
    margin: 20px auto;
  }

}