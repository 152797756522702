.my-web3-info {
  display: block;
  padding: 1.2rem 0.32rem;
}

.my-web3-info .item-text {
  font-weight: 500;
  font-size: 0.28rem;
  color: #fff;
  text-align: left;
}

.my-web3-info .item {
  height: 0.88rem;
  line-height: 0.88rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-web3-info .item .value {
  width: 85%;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-web3-info .item .copy-right {
  width: 0.44rem;
  height: 0.44rem;
  text-align: right;
  cursor: pointer;
  background: url('/public/images/clipboard.png') center no-repeat;
  background-size: contain;
}

.my-web3-info .contact-form {
  background: #000;
  padding: 0 0.24rem 0.48rem 0.24rem;
  border-radius: 0.16rem;
  margin-top: 0.24rem;
}

.my-web3-info .contact-form .form-row {
  line-height: 0.88rem;
}

.my-web3-info .contact-form .form-row label {
  font-weight: 500;
  font-size: 0.28rem;
  color: #fff;
  text-align: left;
}

@media only screen and (min-width: 1280px) {
  .my-web3-info {
    width: 800px;
    margin: 0 auto;
    min-height: 50%;
  }

  .my-web3-info .item-text {
    font-size: 14px;
  }

  .my-web3-info .item {
    height: 40px;
    line-height: 40px;
    justify-content: left;
    margin-bottom: 10px;
  }

  .my-web3-info .item .value {
    font-size: 14px;
    width: auto;
  }

  .my-web3-info .item .copy-right {
    width: 48px;
    height: 48px;
    background-size: 40%;
    text-align: left;
  }

  .my-web3-info .contact-form {
    width:680px;
    padding: 0px 0;
    border-radius: 8px;
    background: transparent;
    margin-top: 0;
  }

  .my-web3-info .contact-form .form-row {
    line-height: 44px;
  }

  .my-web3-info .contact-form .form-row label {
    font-size: 14px;
  }

  .my-web3-info .contact-form .input {
    width: 97%;
  }

  .my-web3-info .contact-form .textarea {
    width: 97%;
    font-size: 14px;
    min-height: 60px;
    border-radius: 8px;
    padding: 0 10px;
  }
}