.history {
  display: block;
  padding: 1.2rem 0.32rem;
}

.history .tags-bar {
  height: 0.68rem;
  line-height: 0.68rem;
  margin-bottom: 0.48rem;
}

.history .tags-bar .tags-list {
  display: flex;
}

.history .tags-bar .tags-list .tag {
  min-width: 1.5rem;
  height: 0.68rem;
  background: rgba(153, 153, 153, 0.1);
  border-radius: 0.32rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  line-height: 0.68rem;
  margin-right: 0.2rem;
  text-align: center;
  cursor: pointer;
  padding: 0 0.24rem;
}

.history .tags-bar .tags-list .chk {
  min-width: 1.5rem;
  height: 0.68rem;
  border-radius: 0.32rem;
  line-height: 0.68rem;
  text-align: center;
  margin-right: 0.2rem;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
  font-weight: 600;
  font-size: 0.28rem;
  color: #3D230D;
  cursor: default;
  padding: 0 0.24rem;
}

.history .list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.history .list li {
  background: #212121;
  border-radius: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 0.24rem;
  cursor: pointer;
  padding: 0.2rem 0;
}

.history .list li .cover {
  width: 26%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.history .list li .cover img {
  width: 1.4rem;
  height: 1.4rem;
  box-shadow: 0 0.14rem 0.34rem 0.04rem #88888833;
  border-radius: 0.16rem;
  object-fit: cover;
}

.history .list li .info {
  width: 74%;
  font-size: 0.24rem;
  color: #B6B6BE;
}

.history .list li .info .title {
  height: 0.4rem;
  font-weight: 500;
  color: #fff;
  line-height: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history .list li .info .operation {
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 0.4rem;
  line-height: 0.4rem;
}

.history .list li .info .operation .addr {
  width: 60%;
  color: #ffffff;
}

.history .list li .info .operation .state {
  width: 35%;
  text-align: center;
}

.history .list li .info .operation .state .green {
  font-weight: 500;
  color: #fff;
}

.history .list li .info .operation .state .red {
  font-weight: 500;
  color: #fff;
}

.history .list li .info .contract {
  height: 0.4rem;
  line-height: 0.4rem;
}

.history .list li .info .contract .chain,
.history .list li .info .contract svg {
  color: #fff;
  vertical-align: middle;
  padding: 0 0.05rem;
}

.history .list li .info .contract .chain {
  text-decoration-line: underline;
  cursor: pointer;
}

.history .list li .info .time {
  height: 0.4rem;
  line-height: 0.4rem;
}

.history .nodata {
  color: #B6B6BE;
  text-align: center;
  font-size: 0.28rem;
}

.history-nft-details {
  display: block;
  text-align: center;
}

.history-nft-details .pic {
  width: 2.44rem;
  height: auto;
  box-shadow: 0 0.02rem 0.2rem 0 #1d1d1d;
  border-radius: 0.32rem;
  margin-bottom: 0.24rem;
  text-align: center;
}

.history-nft-details .rows {
  min-height: 0.46rem;
  font-weight: 600;
  font-size: 0.28rem;
  color: #B6B6BE;
  line-height: 0.46rem;
  text-align: center;
  margin-bottom: 0.2rem;
}

.history-nft-details .rows .chain,
.history-nft-details .rows svg {
  color: #fff;
  vertical-align: middle;
  padding: 0 0.05rem;
}

.history-nft-details .rows .chain {
  text-decoration-line: underline;
  cursor: pointer;
}

/* PC端样式: 当屏幕宽度最小为1280px时应用 */
@media only screen and (min-width: 1280px) {
  .history {
    width: 1200px;
    margin: 0 auto;
    min-height: 50%;
  }

  .history .tags-bar {
    height: 44px;
    line-height: 44px;
    margin-bottom: 20px;
  }

  .history .tags-bar .tags-list {
    display: flex;
  }

  .history .tags-bar .tags-list .tag {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    min-width: auto;
    margin-right: 10px;
  }

  .history .tags-bar .tags-list .chk {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    min-width: auto;
  }

  .history .list li {
    margin-bottom: 20px;
    border-radius: 16px;
  }

  .history .list li .cover {
    width: 14%;
  }

  .history .list li .cover img {
    width: 130px;
    height: 130px;
  }

  .history .list li .info {
    width: 86%;
    font-size: 14px;
  }

  .history .list li .info .operation {
    height: 30px;
    line-height: 30px;
  }

  .history .list li .info .operation .addr {
    width: 80%;
  }

  .history .list li .info .operation .state {
    width: 20%;
    text-align: center;
  }

  .history .list li .info .contract {
    height: 30px;
    line-height: 30px;
  }

  .history .list li .info .time {
    height: 30px;
    line-height: 30px;
  }

  .history .nodata {
    font-size: 14px;
  }

  .history-nft-details .pic {
    width: 180px;
    height: auto;
    margin-bottom: 0;
    border-radius: 16px;
  }

  .history-nft-details .rows {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    min-height: 30px;
    margin-bottom: 0px;
  }

}