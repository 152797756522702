html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #111;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
}

div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app {
  height: 100vh;
  width: 7.5rem;
  display: block;
  margin: 0 auto;
  overflow: auto;
}

.input {
  width: 93%;
  height: 0.88rem;
  background: #212121;
  border-radius: 0.16rem;
  border: none;
  font-weight: 400;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.88rem;
  text-align: left;
  padding: 0 0.24rem;
  transition: border-color 0.3s ease;
  border-color: #F04438;
  outline: none;
}

.select {
  width: 100%;
  height: 0.88rem;
  background: #212121;
  border-radius: 0.16rem;
  border: none;
  font-weight: 400;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.88rem;
  text-align: left;
  padding: 0 0.24rem;
  transition: border-color 0.3s ease;
  border-color: #F04438;
  outline: none;
  margin-bottom: 0.48rem;
}

.textarea {
  width: 93%;
  min-height: 2rem;
  background: #212121;
  border-radius: 0.16rem;
  border: none;
  font-weight: 400;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.44rem;
  text-align: left;
  padding: 0.24rem;
  transition: border-color 0.3s ease;
  border-color: #F04438;
  outline: none;
}

.buy-default,
.buy-failed {
  text-align: center;
}

.buy-default .title,
.buy-failed .title {
  font-weight: 500;
  font-size: 0.32rem;
  color: #ffffff;
  text-align: center;
  margin-top: 0.24rem;
  margin-bottom: 0.24rem;
}

.buy-default .desc,
.buy-failed .desc {
  width: auto;
  height: 0.4rem;
  font-weight: 500;
  font-size: 0.26rem;
  color: #B6B6BE;
  line-height: 0.4rem;
  text-align: center;
  margin-bottom: 0.4rem;
}

.buy-default .nft {
  color: #B6B6BE;
  font-size: 0.32rem;
  font-weight: 500;
  font-style: normal;
  background: #212121;
  border-radius: 0.16rem;
  display: flex;
  justify-content: space-between;
  padding: 0.32rem;
}

.buy-default .nft .nft-pic {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  border-radius: 0.16rem;
}

.buy-default .nft .x {
  color: #fff;
  font-size: 0.28rem;
  font-weight: 600;
}

.buy-default .nft .x5 {
  display: inline-grid;
  text-align: left;
  width: 78%;
}

.buy-default .nft .x5 strong {
  color: #fff;
}

.buy-default .nft .x5 .details {
  color: #B6B6BE;
  font-size: 0.24rem;
  padding: 0;
  margin: 0.2rem 0;
}

.buy-default .nft .x5 .details li {
  margin-left: 0.3rem;
  font-weight: lighter;
}

.buy-default .nft .x5 .details .sbtitle {
  list-style: none;
  color: #B6B6BE;
  text-align: left;
  font-size: 0.24rem;
  margin-left: 0;
  margin-bottom: 0.1rem;
}

.buy-default .nft .x5 .nft-price {
  display: flex;
  justify-content: space-between;
  color: #B6B6BE;
  font-size: 0.24rem;
  background: #212121;
  border-radius: 0 0 0.16rem 0.16rem;
  height: 0.6rem;
  line-height: 0.6rem;
}

.buy-default .nft .x5 .nft-price .lf {
  width: 50%;
  text-align: left;
  color: #fff;
}

.buy-default .nft .x5 .nft-price .rt {
  width: 50%;
  text-align: right;
  padding-right: 0.1rem;
  color: #fff;
}

.buy-default .nft-desc {
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.28rem;
  padding: 0.24rem 0;
  border-top: 0.01rem solid #212121;
  margin-top: 0.2rem;
}

.buy-default .readme {
  color: #B6B6BE;
  font-size: 0.22rem;
  text-align: left;
  margin-bottom: 0.2rem;
}

.buy-default .readme .checkbox {
  transform: scale(1.3);
  margin-right: 0.2rem;
  vertical-align: middle;
}

.buy-default .readme span {
  color: #fff;
  cursor: pointer;
}

.buy-default .cmd-button,
.buy-failed .cmd-button {
  font-weight: bold;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
}

.buy-default .cmd-button .cancel,
.buy-failed .cmd-button .cancel {
  width: 2.45rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #3F3F42;
  border-radius: 0.16rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 0.32rem;
}

.buy-default .cmd-button .cancel:active,
.buy-failed .cmd-button .cancel:active {
  opacity: 0.7;
  background: #888888;
}

.buy-default .cmd-button .ok,
.buy-failed .cmd-button .ok {
  width: 2.45rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  border-radius: 0.16rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 0.32rem;
}

.buy-failed .cmd-button .okmax {
  width: 100%;
}

.buy-default .cmd-button .ok:active,
.buy-failed .cmd-button .ok:active {
  opacity: 0.7;
  background: #DC4530;
}

.buy-default .cmd-button .noused {
  font-size: 0.32rem;
  width: 2.45rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #3F3F42;
  border-radius: 0.16rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  pointer-events: none;
}

.full-modal {
  position: fixed;
  top: -100%;
  left: 0;
  width: 7.5rem;
  height: 100%;
  background-color: #111;
  z-index: 3;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, top 0.5s, visibility 0s 0.5s;
}

.full-modal .modal-main {
  background: #070605;
  border-radius: 0 0 0.48rem 0.48rem;
}

.full-modal .modal-close {
  position: relative;
  background: rgba(255, 255, 255, 0.18) url("/public/images/close.png") center no-repeat;
  width: 0.64rem;
  height: 0.64rem;
  background-size: 60%;
  cursor: pointer;
  border-radius: 50%;
  margin: 0.5rem auto;
  z-index: 0;
}

.show-block {
  top: 0%;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s, top 0.5s;
}

.sway {
  display: inline-block;
  animation: sway 1s infinite;
}

@keyframes sway {

  0%,
  100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(10deg);
  }
}

@media only screen and (min-width: 1280px) {
  .app {
    width: 100%;
  }

  .buy-default .title,
  .buy-failed .title {
    font-size: 16px;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    line-height: auto;
  }

  .buy-default .nft {
    font-size: 14px;
    padding: 20px;
    margin-top: 10px;
  }

  .buy-default .nft .x5 .details {
    margin: 10px 0;
    font-size: 14px;
  }

  .buy-default .nft .x5 .details li {
    margin-left: 20px;
  }

  .buy-default .nft .x5 .details .sbtitle {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .buy-default .nft .x5 .nft-price {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }

  .buy-default .nft-desc {
    font-size: 14px;
    padding: 10px 0;
    margin-top: 10px;
  }

  .buy-default .readme {
    font-size: 14px;
    padding: 10px 0;
    margin-bottom: 10px;
  }

  .buy-default .readme .checkbox {
    transform: scale(1.5);
    margin-right: 10px;
    vertical-align: middle;
  }

  .buy-default .cmd-button,
  .buy-failed .cmd-button {
    font-size: 16px;
  }

  .buy-default .cmd-button .cancel,
  .buy-failed .cmd-button .cancel {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .buy-default .cmd-button .ok,
  .buy-failed .cmd-button .ok {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .buy-default .cmd-button .noused {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .input {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    border-radius: 8px;
  }

  .select {
    width: 680px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 6.342C2.06 5.951 2.293 5.293 2.854 5.293h10.292c.561 0 .794.658.403 1.049l-4.796 4.796a1 1 0 0 1-1.414 0z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }

  .textarea {
    min-height: 60px;
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    border-radius: 8px;
  }

  .full-modal {
    width: auto;
  }

  .full-modal .modal-close {
    width: 44px;
    height: 44px;
    margin: 0 auto;
    margin-top: 20px;
  }
}