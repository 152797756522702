.nft-withdraw {
  display: block;
  padding: 1.2rem 0.32rem;
}

.nft-withdraw .prompt {
  font-weight: 400;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.24rem;
}

.nft-withdraw .value {
  margin-bottom: 0.24rem;
}

.nft-withdraw .tips {
  color: #B6B6BE;
  font-size: 0.28rem;
  padding: 0.32rem 0;
}

.nft-withdraw .tips a,
.nft-withdraw .tips svg {
  color: #fff;
  vertical-align: bottom;
}

.nft-withdraw .tips .zalo {
  display: flex;
  justify-content: space-between;
  height: auto;
  cursor: pointer;
  background: #000;
  border-radius: 0.16rem;
  align-items: center;
}

.nft-withdraw .tips .zalo .icon-zalo {
  background: url('/public/images/funfusion.png') center no-repeat;
  background-size: 0.48rem;
  width: 1rem;
  height: 1rem;
}

.nft-withdraw .tips .zalo .menu-text {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  width: 80%;
  padding-top: 0.32rem;
}

.nft-withdraw .tips .zalo .menu-text .w {
  color: #fff;
}

.nft-withdraw .tips .zalo .menu-text .sm {
  font-size: 0.24rem;
  color: #fff;
  text-align: left;
  font-weight: lighter;
  padding: 0;
  margin: 0.24rem 0 0.32rem 0;
}

.nft-withdraw .tips .zalo .icon-arrow-right {
  background: url('/public/images/arrow-right.png') 70% center no-repeat;
  background-size: 0.12rem 0.2rem;
  width: 1rem;
  height: 1rem;
}

.nft-withdraw .error {
  font-weight: 400;
  font-size: 0.28rem;
  color: #DC4530;
  text-align: left;
  margin-bottom: 0.24rem;
}

.withdraw-confirm {
  display: block;
}

.withdraw-confirm .confirm-title {
  font-weight: 500;
  font-size: 0.32rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0.24rem;
  padding: 0.24rem 0;
}

.withdraw-confirm .confirm-text {
  font-size: 0.24rem;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.24rem;
  padding: 0 0.48rem;
}

.withdraw-confirm .confirm-text span {
  color: #fff;
  padding: 0 0.1rem;
  word-wrap: break-word;
}

.withdraw-confirm .confirm-readme {
  color: #B6B6BE;
  font-size: 0.24rem;
  text-align: left;
  margin-bottom: 0.24rem;
}

.withdraw-confirm .confirm-readme .checkbox {
  transform: scale(1.3);
  margin-right: 0.24rem;
}

.withdraw-confirm .confirm-readme span {
  color: #fff;
  cursor: pointer;
}

.withdraw-confirm .confirm-btns {
  margin-top: 0.4rem;
  margin-bottom: 0.1rem;
}

.withdraw-confirm .confirm-btns .change-wallet {
  height: 0.88rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #ffffff;
  line-height: 0.88rem;
  text-align: center;
  margin-top: 0.24rem;
  cursor: pointer;
  background: #888888;
  border-radius: 0.16rem;
}

.withdraw-confirm .confirm-tips {
  color: #ffffff;
  font-size: 0.28rem;
  padding: 0.32rem 0.12rem;
}

@media only screen and (min-width: 1280px) {
  .nft-withdraw {
    width: 680px;
    margin: 0 auto;
    min-height: 50%;
    text-align: center;
  }

  .nft-withdraw .prompt {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .nft-withdraw .input {
    width: 97%;
  }

  .nft-withdraw .tips {
    font-size: 14px;
    text-align: left;
  }

  .nft-withdraw .tips .zalo {
    border-radius: 8px;
  }

  .nft-withdraw .tips .zalo .icon-zalo {
    background-size: 60%;
    width: 44px;
    height: 44px;
  }

  .nft-withdraw .tips .zalo .menu-text {
    font-size: 14px;
  }

  .nft-withdraw .tips .zalo .menu-text .w {
    font-size: 14px;
  }

  .nft-withdraw .tips .zalo .menu-text .sm {
    font-size: 12px;
    margin: 10px 0 35px 0;
  }

  .nft-withdraw .tips .zalo .icon-arrow-right {
    background-size: 20%;
    width: 44px;
    height: 44px;
  }

  .nft-withdraw .error {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .withdraw-confirm .confirm-title {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .withdraw-confirm .confirm-text {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .withdraw-confirm .confirm-readme {
    font-size: 14px;
    margin-bottom: 20px;
    padding: 0 0.48rem;
  }

  .withdraw-confirm .confirm-readme .checkbox {
    transform: scale(1.5);
    margin-right: 10px;
  }

  .withdraw-confirm .confirm-readme span {
    font-size: 14px;
  }

  .withdraw-confirm .confirm-btns {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .withdraw-confirm .confirm-btns .change-wallet {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    margin-top: 20px;
    border-radius: 8px;
  }

  .withdraw-confirm .confirm-tips {
    color: #ffffff;
    font-size: 14px;
    padding: 20px 0;
  }

}