.topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 1rem;
  line-height: 1rem;
  background-color: #111;
  transition: background-color 0.3s;
  margin: 0 auto;
  padding: 0 0.32rem;
}

.scrolled {
  position: sticky;
  background-color: #111;
}

.topnav .operating {
  width: 90%;
  height: 100%;
  justify-content: left;
  display: flex;
  align-items: center;
}

.topnav .operating .icon-back {
  width: 0.48rem;
  height: 0.48rem;
  background: url("/public/images/arrow.png") center no-repeat;
  background-size: cover;
  cursor: pointer;
  color: #fff;
}

.topnav .operating .logo {
  font-weight: 600;
  font-size: 0.48rem;
  color: #FFFFFF;
  letter-spacing: 0.16rem;
  height: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.topnav .operating .logo img {
  width: 2.32rem;
  height: 0.28rem;
}

.topnav .navigation {
  width: 10%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.topnav .navigation .menus-icon {
  background: #212121 url('/public/images/menus.png') center no-repeat;
  width: 0.72rem;
  height: 0.72rem;
  text-align: center;
  background-size: 0.48rem;
  cursor: pointer;
  border-radius: 50%;
}

.topnav-menus-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  width: 7.5rem;
  background: #111;
  overflow: hidden;
  margin: 0 auto;
  transform: translateX(-200%);
  transition: transform 0.5s ease-in-out;
}

.topnav-menus-dialog .menus-head {
  height: 1rem;
  line-height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  margin: 0 auto;
}

.topnav-menus-dialog .menus-head .menus-head-logo {
  width: 90%;
  justify-content: left;
  display: flex;
  align-items: center;
}

.topnav-menus-dialog .menus-head .menus-head-logo img {
  width: 2.32rem;
  height: 0.28rem;
}

.topnav-menus-dialog .menus-head .menus-head-close {
  width: 10%;
  justify-content: right;
  display: flex;
  align-items: center;
}

.topnav-menus-dialog .menus-head .menus-head-close .close-icon {
  background: #212121 url('/public/images/close.png') center no-repeat;
  width: 0.72rem;
  height: 0.72rem;
  text-align: center;
  background-size: 0.48rem;
  cursor: pointer;
  border-radius: 50%;
}

.topnav-menus-dialog .menus-list {
  padding: 0.48rem;
  height: 80vh;
  overflow: auto;
}

.topnav-menus-dialog .menus-list .li {
  height: 0.72rem;
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.72rem;
  cursor: pointer;
  text-transform: uppercase;
}

.topnav-menus-dialog .menus-list .current {
  height: 0.72rem;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.72rem;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration-line: underline;
}

.topnav-menus-dialog .menus-list .line {
  border-top: 0.01rem dashed #383838;
  margin: 0.48rem 0;
}

.slide-out {
  transform: translateX(0);
}

.pc-navbar {
  display: none;
}

/* PC端样式: 当屏幕宽度最小为1280px时应用 */
@media only screen and (min-width: 1280px) {

  .topnav,
  .topnav-menus-dialog {
    display: none;
  }

  /* pc导航条样式 */
  .pc-navbar {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: block;
  }

  .pc-navbar .navbar {
    width: 1200px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 88px;
    line-height: 88px;
    margin: 0 auto;
  }

  /* LOGO样式 */
  .pc-navbar .navbar-logo img {
    width: 232px;
    height: auto;
    vertical-align: middle;
  }

  .pc-navbar .navbar-menu {
    height: 40px;
    line-height: 40px;
  }

  /* 导航菜单样式 */
  .pc-navbar .navbar-menu ul {
    display: flex;
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .pc-navbar .navbar-menu li {
    color: white;
    transition: background-color 0.3s;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
  }

  .pc-navbar .navbar-menu li:hover,
  .pc-navbar .navbar-menu .achk {
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
  }

  .pc-navbar .navbar-menu .more {
    height: 40px;
    line-height: 20px;
  }

  .pc-navbar .navbar-menu .more .icon-more {
    font-size: 30px;
  }

  .pc-navbar .navbar-menu .more .submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: -80px;
    list-style: none;
    padding: 10px 20px;
    margin: 0;
    background-color: #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .pc-navbar .navbar-menu .more .submenu>li {
    width: 160px;
    height: 40px;
    line-height: 40px;
    color: white;
    transition: background-color 0.3s;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
  }

  .pc-navbar .navbar-menu .more .submenu>li:hover {
    border-bottom: 1px solid #fff;
    text-transform: uppercase;
    color: #b6b6be;
  }

  .pc-navbar .navbar-menu .more:hover .submenu {
    display: block;
  }

  .pc-navbar .custom-toast-container {
    width: auto !important;
  }

}