.pay-result {
  display: block;
  padding: 1.2rem 0.32rem;
}

.pay-result .paystate {
  width: auto;
  margin-top: 0.32rem;
}

.pay-result .paystate .icon {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 auto;
  margin-bottom: 0.3rem;
}

.pay-result .paystate .msg {
  height: 0.46rem;
  font-weight: 500;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.46rem;
  text-align: center;
}

.pay-result .success .icon {
  background: url("/public/images/pay-success.png") center no-repeat;
  background-size: cover;
}

.pay-result .failed .icon {
  background: url("/public/images/pay-failed.png") center no-repeat;
  background-size: cover;
}

.pay-result .pay-nft {
  height: auto;
}

.pay-result .pay-nft .nft-poster {
  margin-bottom: 0.24rem;
  text-align: center;
}

.pay-result .pay-nft .nft-poster img {
  width: 100%;
  height: auto;
  box-shadow: 0 0.02rem 0.2rem 0 #1d1d1d;
  border-radius: 0.32rem;
}

.pay-result .nft-info {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  font-style: normal;
  text-align: left;
}

.pay-result .nft-info .val {
  margin-bottom: 0.2rem;
}

.pay-result .nft-info .val span {
  color: #FF4405;
  padding: 0 0.1rem;
}

.pay-result .line {
  height: 0.02rem;
  background: #fff;
  opacity: 0.1;
  margin-top: 0.24rem;
  margin-bottom: 0.24rem;
}

.pay-result .sent-to {
  height: 0.46rem;
  font-weight: 600;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.46rem;
  text-align: left;
}

.pay-result .sent-user {
  height: 0.88rem;
  display: flex;
  margin-top: 0.2rem;
}

.pay-result .sent-user .sent-face {
  width: 0.88rem;
  margin-right: 0.2rem;
}

.pay-result .sent-user .sent-info {
  overflow: hidden;
}

.pay-result .sent-user .sent-info .num {
  height: 0.46rem;
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.46rem;
  text-align: left;
}

.pay-result .sent-user .sent-info .nickname {
  height: 0.41rem;
  font-weight: 600;
  font-size: 0.3rem;
  color: #B6B6BE;
  line-height: 0.41rem;
  letter-spacing: 0.01rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pay-result .pay-nft-box {
  background: #212121;
  padding: 0.32rem;
  border-radius: 0.32rem;
  margin: 0.32rem 0;
}

@media only screen and (min-width: 1280px) {
  .pay-result {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
    text-align: center;
  }

  .pay-result .paystate {
    margin-top: 0;
  }

  .pay-result .paystate .icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .pay-result .paystate .msg {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }

  .pay-result .pay-nft {
    height: auto;
    margin-bottom: 0px;
  }

  .pay-result .pay-nft .nft-poster {
    margin-bottom: 0px;
  }

  .pay-result .pay-nft .nft-poster img {
    width: 500px;
    height: 500px;
    border-radius: 16px;
  }

  .pay-result .nft-info {
    font-size: 14px;
  }

  .pay-result .nft-info .val {
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .pay-result .line {
    height: 1px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .pay-result .sent-to {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .pay-result .sent-user {
    height: 60px;
    margin-top: 10px;
  }

  .pay-result .sent-user .sent-face {
    width: 60px;
    margin-right: 10px;
    border-radius: 8px;
  }

  .pay-result .sent-user .sent-info .num {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  .pay-result .sent-user .sent-info .nickname {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  .pay-result .pay-nft-box {
    border-radius: 16px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }

}