.nft-details-send {
  display: block;
  padding: 1.2rem 0.32rem;
}

.nft-details-send .pic {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
}

.nft-details-send .pic img {
  width: 100%;
  height: auto;
  box-shadow: 0 0.02rem 0.2rem 0 #1d1d1d;
  border-radius: 0.32rem;
}

.nft-details-send .li {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  margin-bottom: 0.24rem;
}

.nft-details-send .li span {
  color: #FF4405;
  padding: 0 0.1rem;
}

.nft-details-send .line {
  height: 0.02rem;
  background: #fff;
  opacity: 0.1;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
}

.nft-details-send .user {
  height: 0.88rem;
  line-height: 0.88rem;
  margin-top: 0.24rem;
  margin-bottom: 0.24rem;
  display: flex;
}

.nft-details-send .user .face {
  height: 0.88rem;
  width: 0.88rem;
  margin-right: 0.24rem;
  border-radius: 0.16rem;
}

.nft-details-send .user .info {
  width: 80%;
  height: 0.88rem;
}

.nft-details-send .user .info .id {
  height: 0.48rem;
  font-size: 0.28rem;
  color: #fff;
  line-height: 0.48rem;
  text-align: left;
}

.nft-details-send .user .info .name {
  height: 0.4rem;
  font-size: 0.28rem;
  color: #B6B6BE;
  line-height: 0.4rem;
  letter-spacing: 0.01rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65%;
}

.nft-details-send .sure {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  display: flex;
}

.nft-details-send .tips {
  font-size: 0.28rem;
  color: #fff;
  padding: 0.32rem 0;
}

.nft-details-send .sure span {
  font-size: 0.28rem;
  color: #fff;
  padding: 0 0.05rem;
}

.nft-details-send .cmd {
  margin-top: 0.24rem;
  display: flex;
}

.nft-details-send .cmd .yes {
  width: 50%;
  height: 0.88rem;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  border-radius: 0.16rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  cursor: pointer;
  margin-left: 0.24rem;
  border: none;
}

.nft-details-send .cmd .no {
  width: 50%;
  height: 0.88rem;
  background: #888;
  border-radius: 0.16rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #ddd;
  line-height: 0.88rem;
  text-align: center;
  cursor: pointer;
  border: none;
}

.payment {
  display: block;
}

.payment .number {
  font-weight: 500;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
  margin-top: 0.24rem;
}

.payment .fee {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: center;
}

.payment .readme {
  color: #B6B6BE;
  font-size: 0.24rem;
  text-align: left;
  margin-top: 0.24rem;
  margin-bottom: 0.24rem;
}

.payment .readme .checkbox {
  transform: scale(1.3);
  margin-right: 0.24rem;
  vertical-align: middle;
}

.payment .readme span {
  color: #fff;
  cursor: pointer;
}

.payment .btns {
  display: flex;
  margin-top: 0.4rem;
}

.payment .btns .no,
.payment .btns .noused {
  width: 50%;
  height: 0.88rem;
  background: #3F3F42;
  border-radius: 0.16rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  margin-right: 0.3rem;
  cursor: pointer;
  border: none;
}

.payment .btns .noused {
  pointer-events: none;
  margin-right: 0px;
}

.payment .btns .no:active,
.payment .btns .noused:active,
.nft-details-send .cmd .no:active {
  opacity: 0.7;
  background: #888888;
}

.payment .btns .yes {
  width: 50%;
  height: 0.88rem;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  border-radius: 0.16rem;
  font-weight: bold;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  cursor: pointer;
  border: none;
}

.payment .btns .yes:active,
.nft-details-send .cmd .yes:active {
  opacity: 0.7;
  background: #DC4530;
}

@media only screen and (min-width: 1280px) {
  .nft-details-send {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
  }

  .nft-details-send .pic {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .nft-details-send .pic img {
    width: 500px;
    height: 500px;
    border-radius: 16px;
  }

  .nft-details-send .li {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .nft-details-send .line {
    height: 1px;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .nft-details-send .user {
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nft-details-send .user .face {
    height: 60px;
    width: 60px;
    margin-right: 10px;
  }

  .nft-details-send .user .info {
    height: 60px;
  }

  .nft-details-send .user .info .id {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }

  .nft-details-send .user .info .name {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  .nft-details-send .sure {
    height: 60px;
    font-size: 14px;
    line-height: 30px;
    display: flex;
  }

  .nft-details-send .sure span {
    font-size: 14px;
    padding: 0 5px;
  }

  .nft-details-send .cmd {
    margin-top: 0px;
  }

  .nft-details-send .cmd .yes {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .nft-details-send .cmd .no {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .payment .number {
    font-size: 16px;
    margin-top: 0;
    height: 40px;
    line-height: 40px;
  }

  .payment .fee {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
  }

  .payment .readme {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
  }

  .payment .readme .checkbox {
    transform: scale(1.5);
    margin-right: 10px;
    vertical-align: middle;
  }

  .payment .btns {
    margin-top: 20px;
  }

  .payment .btns .no,
  .payment .btns .noused {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .payment .btns .yes {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 8px;
  }

  .nft-details-send .tips {
    font-size: 14px;
  }

}